h1 {
  @include heading-print;

  @media print {
    font-size: 20pt;
  }
}

h2 {
  @include heading-print;

  @media print {
    font-size: 18pt;
  }
}

h3 {
  @include heading-print;

  @media print {
    font-size: 16pt;
  }
}

h4 {
  @include heading-print;

  @media print {
    font-size: 15pt;
  }
}

h5 {
  @include heading-print;

  @media print {
    font-size: 14pt;
  }
}

h6 {
  @include heading-print;

  @media print {
    font-size: 13pt;
  }
}
