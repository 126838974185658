@charset "UTF-8";
@media print {
  iframe,
  object,
  video {
    display: none; } }

@media print {
  main td,
  main li,
  main p {
    color: black;
    font-size: 12pt; } }

@media print {
  br {
    display: none; } }

@media print {
  figure {
    margin: 15px 0; } }

img {
  max-width: 100px;
  height: auto; }

@media print {
  .u-no-print {
    display: none; } }

.o-btn {
  display: none; }

.o-div {
  border: 0;
  height: 1px;
  margin-top: 50px;
  margin-left: 0; }

.o-div--main {
  background: black; }

.o-div--small {
  display: none; }

.o-list {
  padding-left: 35px; }

@media print {
  .o-list--check {
    list-style: none; }
    .o-list--check > li::before {
      content: '';
      display: block;
      position: absolute;
      color: black;
      content: '\2714';
      left: -25px;
      top: 0; } }

.o-list--count {
  list-style: none;
  counter-reset: count-me; }
  .o-list--count > li {
    counter-increment: count-me; }
    .o-list--count > li::before {
      color: black;
      content: counter(count-me) ".";
      text-align: right;
      display: block;
      position: absolute;
      width: 50px;
      left: -60px;
      top: 0; }

.o-list--main {
  list-style: circle; }

.o-media.is-video {
  display: none; }

.c-form {
  display: none; }

@media print {
  h1 {
    color: black;
    line-height: 1;
    page-break-after: avoid;
    orphans: 3;
    widows: 3; } }

@media print {
  h1 {
    font-size: 20pt; } }

@media print {
  h2 {
    color: black;
    line-height: 1;
    page-break-after: avoid;
    orphans: 3;
    widows: 3; } }

@media print {
  h2 {
    font-size: 18pt; } }

@media print {
  h3 {
    color: black;
    line-height: 1;
    page-break-after: avoid;
    orphans: 3;
    widows: 3; } }

@media print {
  h3 {
    font-size: 16pt; } }

@media print {
  h4 {
    color: black;
    line-height: 1;
    page-break-after: avoid;
    orphans: 3;
    widows: 3; } }

@media print {
  h4 {
    font-size: 15pt; } }

@media print {
  h5 {
    color: black;
    line-height: 1;
    page-break-after: avoid;
    orphans: 3;
    widows: 3; } }

@media print {
  h5 {
    font-size: 14pt; } }

@media print {
  h6 {
    color: black;
    line-height: 1;
    page-break-after: avoid;
    orphans: 3;
    widows: 3; } }

@media print {
  h6 {
    font-size: 13pt; } }

.c-modal {
  display: none; }

@media print {
  .c-nav-breadcrumb {
    display: none; } }

@media print {
  .c-news__aside {
    display: none; } }

@media print {
  .c-skip {
    display: none; } }

@media print {
  .l-foot {
    display: none; } }

@media print {
  .l-head {
    display: none; } }

.o-link--aside {
  display: none; }

.o-aside-head--cal {
  display: none; }

.c-quote--aside {
  position: relative;
  margin-left: 50px; }
  .c-quote--aside::before {
    content: '“';
    font-size: 30px;
    display: block;
    position: absolute;
    left: -20px;
    top: -10px; }

.c-count__val {
  font-size: 0; }
  .c-count__val::after {
    content: attr(data-value);
    font-size: 12pt !important; }

.c-tablist {
  display: none; }

.o-media-slider,
.o-media-carousel {
  margin-left: -15px;
  margin-right: -15px; }
  .o-media-slider .o-media,
  .o-media-carousel .o-media {
    display: inline-block;
    vertical-align: middle;
    margin: 15px; }

.c-news-all {
  display: none; }

.c-news--list {
  display: none; }

.o-table {
  text-align: left; }

.o-table--download {
  display: none; }

.c-form {
  display: none; }
