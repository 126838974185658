.o-list--check {
  @media print {
    @include list-without-styles;

    > li {
      &::before {
        @include pseudo-absolute;
        color: $color-black;
        content: '\2714';
        left: -25px;
        top: 0;
      }
    }
  }
}
