iframe,
object,
video {
  @include no-print;
}

main {
  td,
  li,
  p {
    @include content-text-print;
  }
}

br {
  @include no-print;
}

figure {
  @media print {
    margin: 15px 0;
  }
}

img {
  max-width: 100px;
  height: auto;
}
