.o-link--aside {
  display: none;
}

.o-aside-head--cal {
  display: none;
}

.c-quote--aside {
  position: relative;
  margin-left: 50px;

  &::before {
    content: '“';
    font-size: 30px;
    display: block;
    position: absolute;
    left: -20px;
    top: -10px;
  }
}

.c-count__val {
  font-size: 0;

  &::after {
    content: attr(data-value);
    font-size: 12pt !important;
  }
}

.c-tablist {
  display: none;
}

.o-media-slider,
.o-media-carousel {
  margin-left: -15px;
  margin-right: -15px;

  .o-media {
    display: inline-block;
    vertical-align: middle;
    margin: 15px;
  }
}

.c-news-all {
  display: none;
}

.c-news--list {
  display: none;
}

.o-table {
  text-align: left;
}

.o-table--download {
  display: none;
}

.c-form {
  display: none;
}
